import Crud from "../helpers/crud";

const crud = new Crud("advertisement");

export default {
    state() {
        return {
            ...crud.state,

            choices: null,
            residentialBuilding: null,
            locality: null,
            broker: null,
        }
    },

    namespaced: true,

    actions: {
        ...crud.actions,

        async fetchObject(context, {id, query}) {
            const response = await crud.actions.fetchObject.bind(this)(context, {id, query});

            //Chceme ešte odstrániť všetky "$" v určitých fieldoch, ktoré sú potrebné pre správne zobrazenie detailu nehnuteľnosti
            (() => {
                const parseTexts = (texts) => {
                    if (!texts) return [];

                    return texts.map((item) => {
                        item = item.replace(/\$/g, "");
                        return item;
                    });
                }

                response.benefity = parseTexts(response.benefity);
                response.bezprostredne_okolie_nehnutelnosti = parseTexts(response.bezprostredne_okolie_nehnutelnosti);
                response.dispozicie_a_zariadenia = parseTexts(response.dispozicie_a_zariadenia);
                response.stav_a_rekonstrukcie = parseTexts(response.stav_a_rekonstrukcie);

                context.commit("setCurrentObject", response);
            })();

            try {
                if (response.base_property.residential_building) {
                    const residentialBuilding = (await this.$api.get(`residential-building/${response.base_property.residential_building}/`)).data;
                    context.commit("setResidentialBuilding", residentialBuilding);
                }

                if (response.locality)
                    context.commit("setLocality", response.locality);

                if (response.makler)
                    context.commit("setBroker", response.makler);

            } finally {
                context.commit("initialize");
            }
        },

        async fetchObjectsForMap({commit}, query) {
            const objects = (await this.$api.get("advertisement/map/" + query)).data;

            commit("setObjects", objects);
        },

        async initialize({dispatch}) {
            await dispatch("fetchChoices");
        },

        async fetchChoices(context) {
            const promises = [this.$api.get("flat-advertisement/choices/"), this.$api.get("house-advertisement/choices/"), this.$api.get("land-advertisement/choices/"), this.$api.get("commercial-advertisement/choices/")];
            const data = await Promise.all(promises);

            const allChoices = {};

            data.forEach((item) => {
                allChoices[item.config.url.split("-")[0]] = item.data;
            });

            context.commit("setChoices", {
                type: [
                    {value: "flat", label: "Byt"},
                    {value: "house", label: "Dom"},
                    {value: "land", label: "Pozemok"},
                    {value: "commercial", label: "Komerčný priestor"}
                ],
                allChoices,
            });
        },
    },

    mutations: {
        setChoices(state, payload) {
            state.choices = payload;
        },
        setResidentialBuilding(state, payload) {
            state.residentialBuilding = payload;
        },
        setLocality(state, payload) {
            state.locality = payload;
        },
        setBroker(state, payload) {
            state.broker = payload;
        },

        ...crud.mutations,
    },

    getters: {
        ...crud.getters,

        getChoices(state) {
            return state.choices;
        },
        getResidentialBuilding(state) {
            return state.residentialBuilding;
        },
        getLocality(state) {
            return state.locality;
        },
        getBroker(state) {
            return state.broker;
        }
    }
}