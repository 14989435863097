import {createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'

import router from './router'
import store from './store'
import axios from "@/axios/axios";

import "@/css/app.scss";
import 'vue-slider-component/theme/antd.css'
import "vue-multiselect/dist/vue-multiselect.css"

import VueSlider from 'vue-slider-component'
import {Collapse} from 'vue-collapsed'
import Multiselect from 'vue-multiselect'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

import VueApexCharts from "vue3-apexcharts";
import VueGoogleMaps from '@fawmi/vue-google-maps'


const app = createApp(App);

app.config.globalProperties.$api = axios;
store.$api = axios;

//TODO: sfunkcnit to
// app.config.globalProperties.$waitUntilTrue = async (variable) => {
//     await new Promise((resolve) => {
//         console.log(variable)
//         const interval = setInterval(() => {
//             console.log(variable)
//             if (variable) {
//                 clearInterval(interval);
//                 resolve();
//             }
//         }, 50);
//     });
// }

app
  .use(store)
  .use(router)
  .use(VueApexCharts)
  .use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyCj_7x0uB8k3occwtJz_vQvf1Dao2YXMko'
    }
  })
  .component('collapse', Collapse)
  .component('v-slider', VueSlider)
  .component('multiselect', Multiselect)
  .component('pulse-loader', PulseLoader)
  .mount('#mainapp')
