export default {
    namespaced: true,

    state() {
        return {
            user: null,
        }
    },

    mutations: {
        setUser(state, payload) {
            state.user = payload;
        }
    },

    actions: {
        async login(context, payload) {
            const data = (await this.$api.post("auth/login/", payload)).data;
            context.commit("setUser", data.user);
        },

        async register(context, payload) {
            const user = (await this.$api.post("auth/registration/", payload))?.data.user;
            context.commit("setUser", user);

            return user.id;
        },

        // async logout(context) {
        //
        // },

        async attemp(context) {
            try {
                const user = (await this.$api.get("user/me/")).data;

                context.commit("setUser", user);
            } catch {
                context.commit("setUser", null);
            }
        },

        async create(context, payload) {
            const user = (await this.$api.post("user/", payload)).data;

            context.commit("setUser", user);
        }
    },

    getters: {
        getUser(state) {
            return state.user;
        }
    }
}