import Crud from "@/store/helpers/crud";

const crud = new Crud("flat-advertisement");

export default {
    namespaced: true,

    state() {
        return {
            ...crud.state,
        }
    },

    mutations: {
        ...crud.mutations,
    },

    actions: {
        ...crud.actions,
    },

    getters: {
        ...crud.getters,
    }
}