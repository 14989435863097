export default {
    namespaced: true,

    state() {
        return {
            priceMap: null,
            advertisement: null,
            broker: null,
            
            // competetiveOffers: [],
        }
    },

    mutations: {
        setPriceMap(state, payload) {
            state.priceMap = payload
        },
        setAdvertisement(state, payload) {
            state.advertisement = payload
        },
        setBroker(state, payload) {
            state.broker = payload;
        }
    },

    actions: {
        async initEstimation({commit}, priceMapId) {
            const data = (await this.$api.get(`price-map/${priceMapId}/`)).data;
            const advertisementData = (await this.$api.get(`advertisement/${data.advertisement}/?expand=base_property`)).data;

            let broker = null;

            if (advertisementData.makler)
                broker = (await this.$api.get(`safe-staff/${advertisementData.makler}/`)).data;

            commit('setPriceMap', data);
            commit('setAdvertisement', advertisementData);
            commit('setBroker', broker);
        }
    },

    getters: {
        getPriceMap(state) {
            return state.priceMap;
        },
        getAdvertisement(state) {
            return state.advertisement;
        },
        getBroker(state) {
            return state.broker;
        }
    }
}