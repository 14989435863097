import {markRaw} from "vue";

export default class {
    constructor(endpoint) {
        this.state = {
            endpoint,
            objects: [],
            currentObject: null,

            objectCount: null,

            initialized: false
        };

        this.getters = {
            getCurrentObject(state) {
                return state.currentObject;
            },

            getObjects(state) {
                return state.objects;
            },

            getObjectById: (state) => (id) => state.objects.find(o => o.id === id),

            getInitialized(state) {
                return state.initialized;
            }
        };

        this.actions = {
            async fetchObject(context, {id, query}) {
                    const object = (await this.$api.get(`${endpoint}/${id}/${query}`)).data;

                    context.commit("setCurrentObject", object);

                    return object;
            },

            async fetchObjects(context, query="") {
              const objects = (await this.$api.get(`${endpoint}/${query}`)).data;

              if(objects.results) {
                  context.commit("setObjects", objects.results);
                  context.commit("setObjectCount", objects.count);
              } else {
                  context.commit("setObjects", objects);
                  context.commit("setObjectCount", objects.length);
              }
            },

            async patch(context, {payload, id}) {
                if (!id)
                    context.commit("patchCurrentObject", payload);
                else context.commit("patchObjectById", {payload, id});

                const data = (await this.$api.patch(`${endpoint}/${id ? id : context.getters.getCurrentObject.id}/`, payload)).data;

                // eslint-disable-next-line no-prototype-builtins
                if (data.hasOwnProperty("meta"))
                    delete data.meta;

                if (!id)
                    context.commit("patchCurrentObject", data);
                else context.commit("patchObjectById", {data, id});
            },

            async post(context, payload) {
                //Priradím dočasné ID ako (počet všetkých objectov * -1) -> tým pádom sa nestane, že budeme mať duplicitné ID
                const tempId = (context.getters.getObjects.length + 1) * -1;
                payload.id = tempId;

                context.commit("addObject", JSON.parse(JSON.stringify(payload)));

                delete payload.id;

                //Api errors sa handlia v komponentoch
                const data = (await this.$api.post(`${endpoint}/`, payload)).data;

                //Nechceme dotiahnúť meta z API, keďže tento field aj tak nepoužívame a vo vuexe by to overridlo naše meta, kt. máme označené ako raw -> čiže nereaktívne
                // eslint-disable-next-line no-prototype-builtins
                if (data.hasOwnProperty("meta"))
                    delete data.meta;

                context.commit("patchObjectById", {payload: data, id: tempId});

                return data.id;
            },

            async delete(context, id) {
                context.commit("removeObject", id);

                await this.$api.delete(`${endpoint}/${id}/`);
            },
        };

        this.mutations = {
            initialize(state) {
                state.initialized = true;
            },

            setObjectCount(state, payload) {
              state.objectCount = payload;
            },

            setObjects(state, payload) {
                const metaObjects = [];

                state.objects.forEach(obj => {
                    // if(obj.address_text)
                    metaObjects.push(obj.meta);
                });

                state.objects = payload;

                state.objects.forEach((object, index) => {
                    //markRaw nám označí field ako nereaktívny - môžeme ho meniť bez toho, aby sme mali naň napísané mutácie
                    //POZOR!! pokiaľ meníme nejaké dáta, kt. chceme hneď zobraziť treba použiť this.$forceUpdate, čo prerendruje DOM
                    object.meta = metaObjects[index] ? metaObjects[index] : markRaw({});
                });
            },

            //TODO: Prečo to nieje reaktívne, keď posielam shallow kópiu ????
            setCurrentObjectById(state, id) {
                state.currentObject = state.objects.find(object => object.id === id);
            },
            setCurrentObject(state, payload) {
                state.currentObject = payload;

                if (payload)
                    state.currentObject.meta = markRaw({});
            },

            patchCurrentObject(state, payload) {
                Object.assign(state.currentObject, payload);
            },
            patchObjectById(state, {payload, id}) {
                const object = state.objects.find(object => object.id === id);

                Object.assign(object, payload);
            },

            addObject(state, payload) {
                payload.meta = markRaw({});
                state.objects.push(payload);
            },

            removeObject(state, id) {
                const index = state.objects.indexOf(state.objects.find(object => object.id === id));

                state.objects.splice(index, 1);
            }
        };
    }
}
