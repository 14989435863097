import Crud from "../helpers/crud";

const crud = new Crud("advertisement");

export default {
    namespaced: true,
    state() {
        return {
            ...crud.state,

            tours: [],
        }
    },
    mutations: {
        ...crud.mutations,
    },
    actions: {
        ...crud.actions,
    },
    getters: {
        ...crud.getters,
    }
}