import {createStore} from 'vuex'

import estimate from "@/store/modules/estimate";
import residentialBuilding from "@/store/modules/residentialBuilding";
import advertisements from "@/store/modules/advertisements";
import tour from "@/store/modules/tour";

import houseAdvertisement from "@/store/modules/advertisements/house";
import landAdvertisement from "@/store/modules/advertisements/land";
import flatAdvertisement from "@/store/modules/advertisements/flat";
import commercialAdvertisement from "@/store/modules/advertisements/commercial";

import houseProperty from "@/store/modules/properties/house";
import landProperty from "@/store/modules/properties/land";
import flatProperty from "@/store/modules/properties/flat";
import commercialProperty from "@/store/modules/properties/commercial";

import user from "@/store/modules/user";

export default createStore({
    state() {
        return {
            initialized: false,
            animations: []
        }
    },
    getters: {
        getAnimationByKey: (state) => (key) => {
            return state.animations.find(animation => animation.key === key);
        },

        getInitialized(state) {
            return state.initialized;
        }
    },
    mutations: {
        addAnimation(state, animation) {
            state.animations.push(animation);
        },

        setInitialized(state, payload) {
            state.initialized = payload;
        }
    },
    actions: {
        async initialize({commit, dispatch}) {
            await dispatch("advertisements/initialize");
            await dispatch("user/attemp");

            commit("setInitialized", true);
        }
    },
    modules: {
        advertisements,
        residentialBuilding,
        estimate,
        tour,

        houseAdvertisement,
        landAdvertisement,
        flatAdvertisement,
        commercialAdvertisement,

        houseProperty,
        landProperty,
        flatProperty,
        commercialProperty,

        user,
    }
})
