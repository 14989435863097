import Axios from "axios";
// import router from "@/router";

const axiosConfig = {
    // baseURL: "http://localhost:8000/",
    baseURL: "https://api.medexx.sk/",
    withCredentials: true
}

const axios = Axios.create(axiosConfig);
const _axios = Axios.create(axiosConfig);

if(process.env.NODE_ENV === 'production')
    axios.defaults.baseURL = "https://api.medexx.sk/"

axios.interceptors.response.use(
    response => response,
    async error => {
        if(error.response.status === 401) {
            try {
                await _axios.post("auth/token/refresh/");
                return await _axios.request(error.config);
            } catch (e) {
                console.log(e);
                // if(e.response.status === 401)
                //     await router.push({name: 'Login'});
            }
        }

        return Promise.reject(error);
    }
);

export default axios;