import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: "/public/",
        name: "PublicView",
        component: () => import("../views/PublicView.vue"),
        children: [
            {
                path: "inzeraty/",
                name: "PropertySearch",
                component: () => import("../containers/public/AdSearch.vue"),
            },
            {
                path: "inzerat/",
                name: "AdDetailView",
                component: () => import("../views/AdDetailView.vue"),
                children: [
                    {
                        path: "byt/:id/",
                        name: "FlatDetail",
                        component: () => import("../containers/public/ad_details/Flat.vue"),
                    },
                    {
                        path: "pozemok/:id/",
                        name: "LandDetail",
                        component: () => import("../containers/public/ad_details/Land.vue"),
                    },
                    {
                        path: "dom/:id/",
                        name: "HouseDetail",
                        component: () => import("../containers/public/ad_details/House.vue"),
                    },
                    {
                        path: "priestor/:id/",
                        name: "CommercialDetail",
                        component: () => import("../containers/public/ad_details/Commercial.vue"),
                    }
                ]
            },
            {
                path: "odhad/:id/",
                name: "Estimate",
                component: () => import("../containers/public/Estimate.vue"),
            }
        ],
    },
    {
        path: "/formular/",
        name: "FormView",
        component: () => import("../views/FormView.vue"),
        children: [
            {
                path: "odhad/",
                name: "AdForm",
                component: () => import("../containers/public/forms/AdForm.vue"),
            }
        ]
    },
    {
        path: "/auth/login/",
        name: "Login",
        component: () => import("../containers/app/auth/Login.vue"),
    },
    {
        path: "/app/",
        name: "AppView",
        component: () => import("../views/AppView.vue"),
        children: [
            {
                path: "dashboard/",
                name: "Dashboard",
                component: () => import("../containers/app/Dashboard.vue"),
            },
            {
                path: "zaujemci/",
                name: "PotentialBuyers",
                component: () => import("../containers/app/PotentialBuyers.vue"),
            },
            {
                path: "statistiky/",
                name: "DetailedStats",
                component: () => import("../containers/app/DetailedStats.vue"),
            },
            {
                path: "profil/",
                name: "ClientInformations",
                component: () => import("../containers/app/ClientInformations.vue"),
            },
        ]
    },

    {
        path: '/:catchAll(.*)',
        redirect: '/public/inzeraty'
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
