<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  async created() {
    await this.$store.dispatch('initialize');
  }
}
</script>

<style lang="scss">
</style>
